import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import dayjs from "dayjs"
import CallModal from "../../components/CallModal"
import { Box } from "@mui/material"
import useCalls from "../../hooks/useCalls"
import useFilters from "../../hooks/useFilters"
import Filter from "../../components/Filter"
import CallsTable from "../../components/CallsTable"
import { useOutletContext, useSearchParams } from "react-router-dom"
import { useIsMount } from "../../hooks/useIsMount"

export default function Analytics() {
  const isMount = useIsMount()

  const [searchParams, setSearchParams] = useSearchParams()
  const { fields, filters, setFilters } = useFilters(
    "selects/calls/",
    searchParams.get("filters")
      ? JSON.parse(searchParams.get("filters"))
      : false
  )
  const [modalCall, setModalCall] = useState(false)
  const [startDate, setStartDate] = useState(
    searchParams.get("startDate")
      ? dayjs(searchParams.get("startDate"), "DD-MM-YYYY")
      : dayjs(new Date())
  )
  const [endDate, setEndDate] = useState(
    searchParams.get("endDate")
      ? dayjs(searchParams.get("endDate"), "DD-MM-YYYY")
      : dayjs(new Date())
  )
  const [fullTable, setFullTable] = useState(false)
  const [page, setPage] = useState(Number(searchParams.get("page") || 0))
  const updateValue = (setParam, paramName) => (newValue) => {
    setParam(newValue)
    setSearchParams((params) => {
      if (newValue instanceof dayjs) {
        newValue = newValue.format("DD-MM-YYYY")
      }
      params.set(paramName, newValue)
      return params
    })
  }
  const updateFilters = (newValue) => {
    setSearchParams((params) => {
      params.set("filters", JSON.stringify(newValue))
      return params
    })
    setFilters(newValue)
  }
  const [perPage, setPerPage] = useState(10)
  const { calls, setCalls } = useCalls(
    filters,
    fields,
    startDate,
    endDate,
    page,
    perPage
  )
  const pagePadding = useOutletContext()

  useEffect(() => {
    if (!isMount) {
      updateValue(setPage, "page")(1)
    }
  }, [filters, startDate, endDate])

  return (
    <>
      <CallModal
        calls={calls}
        setCalls={setCalls}
        fields={fields}
        onClose={() => updateValue(setModalCall, "modalCall")(false)}
        call={modalCall}
      />
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          gap: pagePadding,
        }}
      >
        <Header
          startDate={startDate}
          setStartDate={updateValue(setStartDate, "startDate")}
          endDate={endDate}
          setEndDate={updateValue(setEndDate, "endDate")}
        />
        <Box
          sx={{
            display: fullTable ? "none" : "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            boxSizing: "border-box",
            gap: "inherit",
          }}
        >
          <Filter
            fields={fields}
            onApplyFilter={(d) => updateFilters(d)}
            onResetFilter={() => updateFilters(false)}
            count={calls?.count ? calls.count : 0}
            checkboxsStats={calls.stats}
            aiStats={calls.ai_stats}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
            borderRadius: "15px",
          }}
        >
          <CallsTable
            filters={filters}
            setFilters={updateFilters}
            page={page}
            setPage={updateValue(setPage, "page")}
            setModalCall={updateValue(setModalCall, "modalCall")}
            calls={calls}
            setCalls={setCalls}
            fullTable={fullTable}
            setFullTalbe={setFullTable}
            rows={perPage}
            setRows={setPerPage}
          />
        </Box>
      </Box>
    </>
  )
}
